import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useFormik } from 'formik';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useUser } from '../lib/auth';
import { useFiltering } from '../lib/hooks';
import { isSuperUser } from '../lib/perms';
import { useTeams } from '../lib/queries';
import { Player } from '../lib/types';
import { classNames } from '../lib/utils';
import { Button, DangerButton, PrimaryButton } from './Buttons';
import { IsSuperUser } from './Permissions';
import { Placeholder, PlaceholderList } from './Placeholders';
import Input from './inputs/Input';
import SearchInput from './inputs/Search';
import Textarea from './inputs/Textarea';
import DropdownSelect from './widgets/DropdownSelect';
import { TablePagination } from './widgets/Table';
import { SuperUserTag } from './widgets/Tag';

type ActionModalProps = {
  onCancel: () => void;
  title: string;
  confirmButtonText?: string;
  confirmButtonDanger?: boolean;
  cancelButtonText?: string;
  icon?: React.ComponentType<React.ComponentProps<'svg'>>;
  narrow?: boolean;
};

type ActionModalButtonsProps = {
  onConfirm?: () => void;
  onCancel: () => void;
  confirmButtonText?: string;
  confirmButtonDanger?: boolean;
  confirmButtonDisabled?: boolean;
  confirmButtonIsSubmit?: boolean;
  cancelButtonDisabled?: boolean;
  cancelButtonText?: string;
};

export const ActionModalButtons: React.FC<ActionModalButtonsProps> = ({
  onCancel,
  onConfirm,
  confirmButtonText,
  confirmButtonDanger,
  cancelButtonText,
  cancelButtonDisabled,
  confirmButtonDisabled,
  confirmButtonIsSubmit,
}) => {
  const { t } = useTranslation();
  const ConfirmButtonComponent = confirmButtonDanger ? DangerButton : PrimaryButton;
  return (
    <div className="mt-4 flex flex-row-reverse">
      <ConfirmButtonComponent
        onClick={onConfirm}
        className="ml-3"
        large
        disabled={confirmButtonDisabled}
        submit={!confirmButtonDanger}
        type={confirmButtonIsSubmit ? 'submit' : undefined}
      >
        {confirmButtonText || t('confirm')}
      </ConfirmButtonComponent>
      <Button onClick={onCancel} large disabled={cancelButtonDisabled}>
        {cancelButtonText || t('cancel')}
      </Button>
    </div>
  );
};

export const ActionModal: React.FC<ActionModalProps> = ({ title, onCancel, icon, narrow, children }) => {
  const { t } = useTranslation();
  const handleCancel = () => {
    onCancel();
  };

  const Icon = icon;

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleCancel}>
        <div
          className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 outline-none"
          tabIndex={0}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-0 scale-95"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-0 scale-95"
          >
            <div
              className={classNames(
                'inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle w-full p-6',
                narrow ? ' max-w-md' : 'max-w-lg'
              )}
            >
              <div className="block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleCancel}
                >
                  <span className="sr-only">{t('close')}</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex items-start">
                {Icon ? (
                  <div className="mr-4 flex-shrink-0 flex items-center justify-center rounded-full bg-red-100 h-10 w-10">
                    <Icon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                ) : null}
                <div className="mt-0 text-left flex-grow">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium break-word">
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">{children}</div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const ConfirmModal: React.FC<
  ActionModalProps & ActionModalButtonsProps & { message: string | React.ReactNode; danger?: boolean }
> = ({ message, danger = true, ...props }) => {
  return (
    <ActionModal confirmButtonDanger={danger} icon={danger ? ExclamationTriangleIcon : undefined} {...props}>
      <p className="text-sm text-gray-500">{message}</p>
      <ActionModalButtons confirmButtonDanger={danger} {...props} />
    </ActionModal>
  );
};

const addCoinsSchema = yup.object({ coins: yup.number().notOneOf([0]).required(), message: yup.string() }).required();

export const AddCoinsModal: React.FC<{
  onConfirm: (coins: number, message?: string, reason?: string) => void;
  onCancel: () => void;
  count: number;
}> = ({ onCancel, onConfirm, count }) => {
  const { t } = useTranslation();
  const user = useUser();

  const awardReasons = useMemo(() => {
    return [
      'transaction:credit.manual',
      'transaction:credit.friendreferral',
      'transaction:credit.welcome',
      'transaction:credit.activitycompleted',
      'transaction:credit.coursecompleted',
      'transaction:credit.chatparticipation',
      'transaction:credit.chatupvoted',
      'transaction:credit.checkedin',
      'transaction:credit.engagedwithsponsor',
      'transaction:credit.meetingbooked',
      'transaction:credit.networking',
      'transaction:credit.profilecompleted',
      'transaction:credit.recordedcontentviewed',
      'transaction:credit.registered',
      'transaction:credit.resourcedownloaded',
      'transaction:credit.scavengerhuntcompleted',
      'transaction:credit.sessionattented',
      'transaction:credit.socialattented',
    ]
      .map((v) => ({ value: v, label: t(v) }))
      .sort((i1, i2) => (i1.label < i2.label ? -1 : 1));
  }, [t]);

  const formik = useFormik({
    initialValues: {
      coins: '',
      message: '',
      reason: awardReasons.find((r) => r.value === 'transaction:credit.manual'),
    },
    onSubmit: ({ coins, message, reason }) => {
      onConfirm(parseInt(coins, 10), message, isSuperUser(user) ? reason?.value : undefined);
    },
    validationSchema: addCoinsSchema,
    validateOnMount: true,
  });

  const onCoinsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e.target.name)(e.target.value.replace(/[^0-9-]/, ''));
  };

  return (
    <ActionModal onCancel={onCancel} title={t('addCoins')} narrow>
      {count > 1 ? (
        <p className="pb-2 text-sm text-gray-500">
          <Trans t={t} i18nKey="youAreGivingCoinsToNPlayers" values={{ count }} />
        </p>
      ) : null}
      <form className="flex flex-col flex-grow" onSubmit={formik.handleSubmit}>
        <div className="flex-grow space-y-2">
          <div className="w-full">
            <label>
              <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('coins')}</div>
              <div className="w-full">
                <Input name="coins" value={formik.values.coins} onChange={onCoinsChange} onBlur={formik.handleBlur} />
              </div>
            </label>
          </div>
          <IsSuperUser>
            <div className="w-full">
              <div className="leading-5 mb-1 text-gray-700 text-sm font-medium flex items-center">
                Award type <SuperUserTag />
              </div>
              <div>
                <DropdownSelect
                  options={awardReasons}
                  selected={formik.values.reason}
                  onChange={(v) => formik.setFieldValue('reason', v)}
                  placeholder=""
                />
              </div>
            </div>
          </IsSuperUser>
          <div className="w-full">
            <label>
              <div className="leading-5 mb-1 text-gray-700 text-sm font-medium flex items-center">
                <div>{t('messageToPlayer')}</div>
                <div className="ml-6 text-gray-500">{t('optional')}</div>
              </div>
              <div>
                <Textarea name="message" value={formik.values.message} onChange={formik.handleChange} onBlur={formik.handleBlur} />
              </div>
            </label>
          </div>
        </div>
        <ActionModalButtons onCancel={onCancel} confirmButtonText={t('addCoins')} confirmButtonDisabled={!formik.isValid} />
      </form>
    </ActionModal>
  );
};

const addTicketsSchema = yup.object({ amount: yup.number().notOneOf([0]).required() }).required();

export const AddTicketsModal: React.FC<{
  onConfirm: (amount: number) => void;
  onCancel: () => void;
  count?: number;
}> = ({ onCancel, onConfirm, count = 1 }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    onSubmit: ({ amount }) => {
      onConfirm(parseInt(amount, 10));
    },
    validationSchema: addTicketsSchema,
    validateOnMount: true,
  });

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e.target.name)(e.target.value.replace(/[^0-9-]/, ''));
  };

  return (
    <ActionModal onCancel={onCancel} title={t('addTickets')} narrow>
      {count > 1 ? (
        <p className="pb-2 text-sm text-gray-500">
          <Trans t={t} i18nKey="youAreGivingTicketsToNPlayers" values={{ count }} components={[<strong />]} />
        </p>
      ) : null}
      <form className="flex flex-col flex-grow" onSubmit={formik.handleSubmit}>
        <div className="flex-grow space-y-2">
          <div className="w-full">
            <label>
              <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('tickets')}</div>
              <div className="w-full">
                <Input name="amount" value={formik.values.amount} onChange={onAmountChange} onBlur={formik.handleBlur} />
              </div>
            </label>
          </div>
        </div>
        <ActionModalButtons onCancel={onCancel} confirmButtonText={t('addTickets')} confirmButtonDisabled={!formik.isValid} />
      </form>
    </ActionModal>
  );
};

export const DeletePlayerModal: React.FC<{ onDelete: () => void; onCancel: () => void; count?: number }> = ({
  onDelete,
  onCancel,
  count = 1,
}) => {
  const { t } = useTranslation();
  return (
    <ConfirmModal
      onConfirm={onDelete}
      onCancel={onCancel}
      title={t('deletePlayer', { count })}
      message={<Trans t={t} i18nKey="confirmDeletePlayer" count={count} />}
      confirmButtonText={t('delete')}
    />
  );
};

const getDuplicateName = (name: string) => {
  const parts = name.match(/^(.*) \((\d+)\)$/);
  if (!parts) return `${name} (1)`;
  return `${parts[1]} (${parseInt(parts[2], 10) + 1})`;
};

export const DuplicateModal: React.FC<{
  onConfirm: (name: string) => void;
  onCancel: () => void;
  baseName: string;
}> = ({ onCancel, onConfirm, baseName }) => {
  const { t } = useTranslation();
  return (
    <TextEntryModal
      onConfirm={onConfirm}
      onCancel={onCancel}
      title={t('duplicateItem', { count: 1 })}
      text={getDuplicateName(baseName)}
      confirmButtonText={t('duplicate')}
      required
    />
  );
};

const textEntryModalSchema = yup.object({ text: yup.string() }).required();
const textEntryModalRequiredSchema = yup.object({ text: yup.string().required() }).required();

export const TextEntryModal: React.FC<{
  onConfirm: (name: string) => void;
  onCancel: () => void;
  title: string;
  placeholder?: string;
  text?: string;
  required?: boolean;
  label?: string | React.ReactNode;
  confirmButtonText?: string;
}> = ({ onCancel, onConfirm, text, placeholder, required, label, confirmButtonText, title }) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: { text: text || '' },
    onSubmit: ({ text }) => {
      onConfirm(text);
    },
    validationSchema: required ? textEntryModalRequiredSchema : textEntryModalSchema,
    validateOnMount: true,
  });

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
      ref.current.select();
    }
  }, []);

  return (
    <ActionModal onCancel={onCancel} title={title}>
      <form className="mt-4 flex flex-col flex-grow" onSubmit={formik.handleSubmit}>
        <div className="flex-grow space-y-2">
          <div className="w-full">
            <label>
              <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{label || t('name')}</div>
              <div className="w-full">
                <Input
                  ref={ref}
                  name="text"
                  value={formik.values.text}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={placeholder}
                />
              </div>
            </label>
          </div>
        </div>
        <ActionModalButtons
          confirmButtonIsSubmit
          onCancel={onCancel}
          confirmButtonText={confirmButtonText || t('save')}
          confirmButtonDisabled={!formik.isValid}
        />
      </form>
    </ActionModal>
  );
};

export const ChangePlayerTeamModal: React.FC<{
  onConfirm: (teamId: string) => void;
  onCancel: () => void;
  player: Player;
}> = ({ onCancel, onConfirm, player }) => {
  const { t } = useTranslation();
  const [teamId, setTeamId] = useState();
  const { filterTerm, filterInputProps } = useFiltering();
  const {
    showingFrom,
    showingTo,
    showingOfTotal,
    isSuccess,
    data,
    hasPreviousPage,
    hasNextPage,
    fetchNextPage,
    fetchPreviousPage,
  } = useTeams(filterTerm, undefined, 10);
  const canSubmit = Boolean(teamId);

  const handleConfirm = useCallback(() => {
    if (!teamId) return;
    onConfirm(teamId);
  }, [teamId, onConfirm]);

  useEffect(() => {
    if (!teamId) {
      return;
    } else if (!data) {
      setTeamId(undefined);
    } else if (teamId === player.school_id) {
      setTeamId(undefined);
    } else if (!data.items.some((team) => team.id === teamId)) {
      setTeamId(undefined);
    }
  }, [data, setTeamId, teamId, player]);

  return (
    <ActionModal onCancel={onCancel} title={t('changeTeam')}>
      <div>
        <div className="mb-2">
          <SearchInput {...filterInputProps} />
        </div>
        {isSuccess ? (
          !data?.items.length ? null : (
            <div className="flex-grow border border-gray-200">
              <RadioGroup value={teamId} onChange={setTeamId}>
                {data?.items.map((team) => {
                  const isCurrent = team.id === player.school_id;
                  return (
                    <RadioGroup.Option value={team.id} key={team.id} as={React.Fragment} disabled={isCurrent}>
                      {({ checked }) => (
                        <div
                          className={classNames(
                            'flex items-center gap-2 p-4 text-sm font-medium border-b',
                            'last:border-0',
                            checked ? 'bg-blue-100 border-blue-100' : 'border-gray-200',
                            isCurrent ? 'bg-gray-100 text-gray-400 cursor-default' : 'cursor-pointer'
                          )}
                        >
                          <input
                            type="radio"
                            checked={checked}
                            disabled={isCurrent}
                            className="border-gray-300 cursor-pointer disabled:cursor-default disabled:border-gray-200 disabled:bg-gray-200 disabled:hover:bg-gray-200"
                            readOnly
                          />
                          <div>{team.name}</div>
                        </div>
                      )}
                    </RadioGroup.Option>
                  );
                })}
              </RadioGroup>
            </div>
          )
        ) : (
          <div className="space-y-2">
            <PlaceholderList count={3}>
              <Placeholder className="h-12" />
            </PlaceholderList>
          </div>
        )}
        {isSuccess && (data?.total || 0) > 0 ? (
          <div className={classNames('mt-2', hasPreviousPage || hasNextPage ? 'border-b pb-2' : null)}>
            <TablePagination
              glued
              largeButtons={false}
              showingFrom={showingFrom}
              showingTo={showingTo}
              showingOfTotal={showingOfTotal}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              onNextPageClick={fetchNextPage}
              onPreviousPageClick={fetchPreviousPage}
            />
          </div>
        ) : null}

        <ActionModalButtons
          onCancel={onCancel}
          confirmButtonText={t('confirm')}
          confirmButtonDisabled={!canSubmit}
          onConfirm={handleConfirm}
        />
      </div>
    </ActionModal>
  );
};
